import * as React from 'react';

import { BlockKind, BlockKindType, BlockSection } from '../block/common';
import { Permission } from '../permissions';
import { CabinetManagerRoleEntity, ProjectManagerRoleEntity } from '../manager';
import { ChannelKind, ChannelKindType, ChannelListItem } from '../channels';
import { CommonIconProps } from '../components/Icon';
import { INewList } from '../list';
import {
  BlockComponentsMap,
  BlockConfigMap,
  BlockStaticConfigMap
} from '../block/constructors';
import { IAuthFlowStoreConstructor } from '../store/authFlowStore';
import { ChannelCreationComponentProps } from '../components/ChannelCreation';
import { TranslationString } from '../localization';

export enum AuthBenefitsStage {
  messengers = 'messengers',
  reviews = 'reviews',
  verticalSlider = 'verticalSlider'
}

export enum HeaderBlockItemType {
  common = 'common',
  cluster = 'cluster'
}

export type HeaderBlockItemBase = {
  name: TranslationString | string;
  Icon: React.ComponentType<CommonIconProps>;
  label?: TranslationString;
};

export type HeaderBlockItemCommon = HeaderBlockItemBase & {
  type: HeaderBlockItemType.common;
  kind: BlockKindType;
  dock?: string;
  onlyForSa?: boolean;
};

export type ClusterHeaderBlockItem = HeaderBlockItemBase & {
  type: HeaderBlockItemType.cluster;
  items: HeaderBlockItemCommon[];
};

export type HeaderBlockItem = HeaderBlockItemCommon | ClusterHeaderBlockItem;

export type BlocksListType = {
  title: string;
  items: HeaderBlockItem[];
};

export type BlocksPlugin = {
  blockConstructorMap?: BlockConfigMap;
  blockStaticMap?: BlockStaticConfigMap;
  blockComponentsMap?: BlockComponentsMap;
  extraBlocksInBlockSection?: Record<BlockSection, HeaderBlockItem[]>;
  extraSettings?: {
    globalInputParam: {
      defaultValue: true;
    };
  };
  excludeBlocks?: BlockKind[];
};

export type ChannelsPlugin = {
  extraChannels: {
    order: ChannelKindType[];
    entities: Record<
      ChannelKindType,
      ChannelListItem & {
        ChannelCreationComponent: React.FC<ChannelCreationComponentProps>;
      }
    >;
  };
  hiddenChannels: ChannelKind[];
};

export type ManagersPlugin = {
  cabinetManagerForm?: React.FC<{
    cabinetManagersRoles: INewList<CabinetManagerRoleEntity>;
  }> | null;
  projectManagerForm?: React.FC<{
    projectManagersRoles: INewList<ProjectManagerRoleEntity>;
    cabinetManagersRoles: INewList<CabinetManagerRoleEntity>;
  }> | null;
  // не учитываемые права
  notConsideredPermissions: Permission[];
};

export type VariablesPlugin = {
  allowProtected: boolean;
};

export type ModulesPlugin = {
  clustering: boolean;
  instruction: boolean;
};

export type AuthPlugin = {
  pages: Record<
    string,
    {
      Component: React.FC;
      stage: AuthBenefitsStage;
    }
  >;
  Store: IAuthFlowStoreConstructor;
  redirectToAuthUrl: string;
};

export type AnalyticsPlugin = {
  enabled: boolean;
};

export type InternationalDomainPlugin = {
  turnOn: boolean;
};

export type AppPlugins = {
  blocks?: BlocksPlugin;
  channels?: ChannelsPlugin;
  variables?: VariablesPlugin;
  modules?: ModulesPlugin;
  managers?: ManagersPlugin;
  auth?: AuthPlugin;
  analytics?: AnalyticsPlugin;
  internationalDomain?: InternationalDomainPlugin;
};

export const defaultModulesAccess: ModulesPlugin = {
  clustering: true,
  instruction: true
};

export const SMARTBOT_ENVIRONMENT = process.env.IS_PROD
  ? 'production-en'
  : (process.env.SMARTBOT_PRO_ENVIRONMENT as string);
